.form-input {
    display: contents;
}

.form-input .MuiFormLabel-root {
    font-size: 12px !important;
}

.section-button a {
    margin-top: 10px;
    font-size: 12px;
}


.buttonRecovery {
    margin: 20px 0 0 0;
    cursor: pointer;
    font-size: 16px;
}

.buttonRecovery:hover {
    text-decoration: underline;
}

.captchaIcon lord-icon {
    display: flex;
    width: 100px;
    height: 100px;
    margin: auto;
}

.error {
    color: #4f1091;
    font-family: Nunito;
    text-align: center;

}

Turnstile #cf-stage {
    display: none;
}

@media (max-width: 600px) {
    .form-input .MuiFormLabel-root {
        font-size: 8px !important;
    }



    .form-input {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 250px;
        margin: auto;
        padding: 0;
    }

    .form-input label+.css-1ptx2yq-MuiInputBase-root-MuiInput-root {
        margin-top: 5px;
    }

    .form-input p {
        text-align: left;
        font-size: 10px;

    }


    .MuiFormLabel-root .MuiFormLabel-root {
        font-size: 6px !important;
        text-align: center;
    }

    .slider-captcha {
        display: flex;
        justify-content: center;

    }

    .geetest_captcha .geetest_holder .geetest_content,
    .geetest_popup_wrap .geetest_holder .geetest_content {
        margin: auto;
    }
}


@media (min-width: 600px) {
    .form-input .MuiFormLabel-root {
        padding: 0;
        text-align: center;
    }
}