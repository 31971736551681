.container-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 410px;
    height: auto;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 12px;
}


.body-card {
    width: 400px;
}

.form-title {
    margin-top: 20px;
    display: flex;
    width: 400px;
    justify-content: center;
}

.text-data {
    font-weight: bold;
    text-align: center;
    font-size: 24px;
}

.form-card {
    margin: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.section-button {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.slider-captcha {
    display: flex;
    justify-content: center;
    margin: 30px auto;
    align-content: stretch;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: column-reverse;
}
.contentErrorPadre p{
    text-align: center;
    color: red;

}

.section-button Button {
    background: #5664D2;
    color: #fff;
    width: 100px;
    height: 40px;
    padding-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 5px 0px 5px rgba(0, 0, 0, 0.10);
}

.section-button Button:hover {
    color: #fff;
    background-color: #5664D2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 5px 0px 5px rgba(0, 0, 0, 0.10);
}

.footer-card {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 80px;
    border-radius: 0 0 30px 30px;
}




.section-button {
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
}

.error-captcha {
    text-align: center;
}

.body-card .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    margin: 0px 0 5px 0;
}

@media (max-width: 600px) {
    .card-content {
        width: 90%;
    }

    .container-card {
        width: 90%;
        margin: auto;
    }

    .text-data {

        font-size: 16px;
    }
}



#button-card:disabled {
    color: #fff;
    opacity: 0.5;
}

#captcha .geetest_logo {
    display: none;
}

#captcha .geetest_box_wrap .geetest_box .geetest_footer .geetest_footer_right .geetest_box_logo {
    width: 0px;
}

.code-verification {
    background: #5664D2 !important;
    margin-top: 20px;
    color: #fff !important;
    width: 100px;
    height: 40px;
    padding-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%), 5px 0px 5px rgb(0 0 0 / 10%);
}

.content-form {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 10px);
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root.Mui-disabled {
    color: #fff;
    opacity: 0.5;
}