.modalSucess .css-ypiqx9-MuiDialogContent-root {
    padding: 0;
    margin: auto;
}

.iconSuccess {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.iconSuccess lord-icon {
    height: 100px;
    width: 100px;
}

.iconSuccess p {
    text-align: center;
    margin: auto;
    font-size: clamp(0.8rem, 0.8vw, 1rem);
    font-weight: bold;
}

@media (max-width: 740px) {
    .modalSucess .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
        width: 40%;
        padding: 5px;
    }
}