.container-table-cobros {
    margin-top: 20px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}
.modalTable .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    width: 50%;
}

.title {
    text-align: center;
    font-size: clamp(1rem, 0.8vw, 1rem);
}

.t-head th {
    font-size: clamp(0.8rem, 0.8vw, 1rem);
    text-align: center;
    background-color: #4F9AF7;
    color: #fff;
    font-weight: bold;
    height: 40px;
}

.t-body td {
    text-align: center;
    font-size: clamp(0.8rem, 0.8vw, 1rem);

}

@media (max-width: 390px) {

    .modalTable .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
        margin: 0;
        width: 97%;

    }

    .modalTable .css-ypiqx9-MuiDialogContent-root {
        width: 95%;
        padding: 0;
        margin: auto;
    }

    .t-body td {

        padding-left: 8px;
        padding-right: 8px;
    }




}