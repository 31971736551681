.modalEdit h2 {
    font-size: clamp(1rem, 1.3vw, 1.2rem);
    text-align: left;
}

.errorStyle {
    margin: 0;
    text-align: left;
    color: red;
    font-size: 12px;
}

.fecha {
    margin-top: 30px;
}

.title-two {
    margin-bottom: 20px;
}

.sectionButton {
    display: flex;
    justify-content: space-evenly;

}

.pFecha{
    text-align: justify;
    font-size: clamp(0.7rem, 0.7vw, 0.9rem);
    color: red;
}

  @media only screen and (min-width: 620px) {
    .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
        width: 20%;
    }
}

@media only screen and (max-width: 420px) {
    .modalEdit .MuiDialog-container.MuiDialog-scrollPaper.css-hz1bth-MuiDialog-container {
        width: 90%;
        margin: auto;
    }
}