body {
    margin-top: 80px;
}



.container {
    position: relative;
    width: 100%;
}

.cardContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.cardSuscripcion {
    justify-content: center;
    height: auto;
    flex-direction: column;
    border-radius: 12px;
    background: #fff;
    border: 2px solid #49B2FB;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-bottom: 20px;
}

.headerColor {
    justify-content: center;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 70px;
    position: fixed;
    transition: 0.7s;
}

.headerColor.strick {
    background: #4D39E9;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    height: 5rem;
}

.header {
    width: 50%;
    margin: auto;
}

.headerSuscripcion {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    color: #4D39E9;
    transition: all .3s ease-in;
    font-size: clamp(0.7rem, 0.8vw, 2rem);
}

.strick .headerSuscripcion {
    transform: translateY(15px);
}

.strick .headerSuscripcion h1 {
    margin-top: 10px;
    color: #fff;
}

.strick hr {
    display: none;
}

hr {

    margin-bottom: 20px;
    width: 100%;
    height: 2px;
    background: #4D39E9;
}

.salir Button lord-icon {
    width: 40px;
    height: 40px;
}

.icon {
    display: flex;
    justify-content: space-between;
    margin: 20px;

}

.icon Button {
    border: 1px solid #4D39E9;
    transition: transform 0.3s;
    align-items: flex-start;

}

.iconTarjet {
    display: flex;
    width: 30%;
    height: 150px;
    border: 1px solid #4D39E9;
    justify-content: space-around;
    border-radius: 5px;
    cursor: pointer;
}

.iconTarjet:nth-child(1) {
    border: 1px solid #4D39E9;
    display: flex;
    align-items: flex-start;

}

.iconTarjet:nth-child(1):hover {
    transition: transform 0.3s;

}

.iconTarjet:hover {
    transform: scale(1.03);
    border: 2px solid #4D39E9;
}

.Button {
    width: 200px;
    height: 100px;
}

.divTitle {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    text-align: center; 
    width: 100%; 
    padding-top: 8px; 
    gap: 8px;
}

.titleIcon {
    color: #4D39E9;
    font-size: clamp(0.5rem, 0.8vw, 2rem);
    font-weight: bold;
    text-align: center;
}

.subTitleIcon {
    font-size: 10px;
    text-align: center;
    text-transform: lowercase;
    text-align: justify;
}



.titleIconInactive {
    color: rgb(0 0 0 / 25%);
    font-size: clamp(0.5rem, 0.8vw, 2rem);
    font-weight: bold;
    text-align: center;
    padding-left: 10px;
    height: 20px;
    border: none !important;
}

.iconTarjet lord-icon {
    display: flex;
    width: 150px;
    height: 140px;
}

.suscripcion {
    margin-left: 25px;
    display: flex;
    align-items: center;
    height: 20px;
}

.planTitle {
    font-size: clamp(1rem, 0.9vw, 2rem);
    color: #4D39E9;
}

.chip {
    margin-left: 5px;
}

.planName {
    margin: 10px;
    text-align: center;
    display: flex;
    font-size: clamp(1rem, 0.8vw, 2rem);
    font-weight: bold;
    color: #4D39E9;
}

.detalles {
    display: flex;
    align-items: center;
    margin: 20px;
}

table {
    border-collapse: collapse;
    border-collapse: separate !important;
}

.tableContent {
    width: 100%;
    border: 1px solid #49B2FB;
    border-radius: 5px;
}

.itemSuscripcion {
    display: flex;
    border-bottom: 1px solid #49B2FB;
    height: 35px;
    align-items: center;
}

.itemSuscripcion:nth-child(4) {
    border-bottom: 0;
}

.titleSuscripcion {
    width: 30%;
    text-align: left;
    color: #4D39E9;
    margin-left: 10px;
    font-size: clamp(0.9rem, 0.8vw, 2rem);

}

.descripcion {
    color: #4D39E9;
    font-size: clamp(0.9rem, 0.8vw, 2rem);
    margin-left: 10px;
}

.desuscription {
    font-size: clamp(0.8rem, 0.8vw, 2rem);
    align-items: center;
    justify-content: center;
    margin: auto;
    align-items: flex-end;
}

.pcorreo {
    font-size: 12px;
    margin: 20px;


}

@media (max-width: 740px) {
    .cardContainer {
        width: 95%;
    }

    .header {
        width: 95%;
    }

    .headerSuscripcion h1 {
        margin-bottom: 0;
    }

    .strick .headerSuscripcion {
        margin-top: -10px;
    }

    .headerColor.strick {
        height: 4.5rem;
    }

    .salir {
        height: 30px;
    }

    .salir Button lord-icon {
        width: 30px;
        height: 30px;
    }

    .iconTarjet {
        flex-direction: column;
    }

    .icon Button {
        align-items: center;
        height: auto;
    }

    .iconTarjet lord-icon {
        display: flex;
        width: 80px;
        height: 80px;
    }

    .iconTarjet:nth-child(1) {
        align-items: center;
        height: auto;
    }

    .css-1coqiuc-MuiChip-root {
        width: 70px;
        margin-left: 0;
    }

    .titleSuscripcion {
        width: 40%;
    }

}

@media (min-width: 741px) {
    .cardContainer {
        width: 80%;
    }

    .header {
        width: 80%;
    }
}

@media (min-width: 1200px) {
    .cardContainer {
        width: 50%;
    }

    .header {
        width: 50%;
    }
}

@media (max-width: 320px) {
    .desuscription {
        font-size: 10px;

    }
}